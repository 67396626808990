/* @import url('../src/fontawesome-free-6.5.1-web/css/all.css'); */
*,::after,::before{
  box-sizing: border-box;
}
/* bdd6ef */
.bgcolor{
  background-color: #31363F !important;
}
body{
  font-family: 'Poppins', sans-serif !important;
  font-size: 0.875rem !important;
  opacity: 1 !important;
  overflow-y: scroll !important;
  margin: 0 !important;
}

li{
  list-style: none !important;
}

a{
  cursor: pointer !important;
  text-decoration: none !important;
  font-family: 'Poppins',sans-serif !important;

}
.list-unstyled{
  transition: all 0.5 ease-in-out !important;
}

h4{
  font-family: 'Poppins',sans-serif;
  font-size: 1.275rem;
  color: var(--bs-emphasis-color);
}

/* layout for admin dashboard */
.wrapper{
  align-items: stretch;
  display: flex;
  width: 100%;
}
#sidebar{
  max-width: 264px;
  min-width: 264px;
  background: var(--bs-dark);
  transition: all .3s ease-out;
}
.main{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 0;
  overflow: hidden;
  transition: all 0.35s ease-in-out;
  width: 100%;
}

/*  */

.sidebar-logo{
  padding: 1.15rem;
}
.sidebar-logo a{
  color: #e9ecef;
  font-size: 1.15rem;
  font-weight: 600;
}
.sidebar-nav{
  flex-grow: 1; 
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
  margin-left: 1rem; 
}
.sidebar-header{
  color: #e9ecef;
  font-size: .75rem;  
  padding : 1.5rem 1.5rem .375rem;
}
a.sidebar-link{
  padding: .625rem 0rem .625rem .625rem;
  color: #e9ecef;
  position: relative;
  display: block;
  font-size: 0.875rem;
  transition: all 0.3s ease-in-out;

}
.sidebar-link[data-bs-toggle="collapse"]::after{
  border:solid;
  border-width:0 .075rem .75rem 0 ;
  content: "";
  display: inline-block;
  padding: 2px;
  position: absolute;
  right: 1.5rem;
  top:1.4rem;
  transform: rotate(-135deg);
  transition: all 0.3s ease-out;
}

.avatar{
  height: 40px;
  width: 40px; 
}

.navbar-expand .navbar-nav{
  margin-left: auto;
}

.navhome{
  margin-right: 1rem;
  height: 30px;
  width: 30px;
  align-self: self-end;
}

.content{
  flex: 1;
  max-width: 100vw;
  width: 100vw; 
}
@media (min-width:768px){
  .content{
      max-width: auto;
      width: auto;
  }
}

.card{
  box-shadow: 0 0 .875rem 0 rgb(34, 46, 60,.05);
  margin-bottom: 24px;
}
.illustration{
  /* background-color: var(--bs-primary-bg-subtle); */
  color: var(--bs-emphasis-color);
}
.illustration-img{
  max-width: 150px;
  width: 100%;
}
#sidebar,#SScollapsed{

  background: #343a40;
  margin-left: -264px;
  transition: all .7s ease-out;
}
#collapsed{
  max-width: 264px;
  min-width: 264px;
  background: var(--bs-dark);
  transition: all .7s ease-out;
}

/* Footer and Nav */
.footer{
  margin-top: 200px;
}
@media (max-width:767.98px){
  .navbar,
  footer{
      width: 100vw;
  }
  .banner{
      height: 500px;
      width: 1000px;
      margin: auto;
      padding: 15px 15px;
}
}
.banner{
  height: 25vh;
  width: 500px;
  padding: 1px;
  margin: auto;
}












/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

::after,
::before {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
}

li {
    list-style: none;
}

body {
    font-family: 'Poppins', sans-serif;
}

.wrapper {
    display: flex;
}

.main {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
    overflow: hidden;
    transition: all 0.35s ease-in-out;
    background-color: #fff;
    min-width: 0;
}

#sidebar {
    width: 70px;
    min-width: 70px;
    z-index: 1000;
    transition: all .25s ease-in-out;
    background-color: #0e2238;
    display: flex;
    flex-direction: column;
}

#sidebar.expand {
    width: 260px;
    min-width: 260px;
}

.toggle-btn {
    background-color: transparent;
    cursor: pointer;
    border: 0;
    padding: 1rem 1.5rem;
}

.toggle-btn i {
    font-size: 1.5rem;
    color: #FFF;
}

.sidebar-logo {
    margin: auto 0;
}

.sidebar-logo a {
    color: #FFF;
    font-size: 1.15rem;
    font-weight: 600;
}

#sidebar:not(.expand) .sidebar-logo,
#sidebar:not(.expand) a.sidebar-link span {
    display: none;
}

#sidebar.expand .sidebar-logo,
#sidebar.expand a.sidebar-link span {
    animation: fadeIn .25s ease;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.sidebar-nav {
    padding: 2rem 0;
    flex: 1 1 auto;
}

a.sidebar-link {
    padding: .625rem 1.625rem;
    color: #FFF;
    display: block;
    font-size: 0.9rem;
    white-space: nowrap;
    border-left: 3px solid transparent;
}

.sidebar-link i,
.dropdown-item i {
    font-size: 1.1rem;
    margin-right: .75rem;
} */

a.sidebar-link:hover {
    background-color: rgba(0, 34, 159, 0.075);
    border-left: 3px solid #4a5cf2;
    color:#1c1b1b64!important;
}

.sidebar-item {
    position: relative;
}

#sidebar:not(.expand) .sidebar-item .sidebar-dropdown {
    position: absolute;
    top: 0;
    left: 70px;
    background-color: #0e2238;
    padding: 0;
    min-width: 15rem;
    display: none;
}

#sidebar:not(.expand) .sidebar-item:hover .has-dropdown+.sidebar-dropdown {
    display: block;
    max-height: 15em;
    width: 100%;
    opacity: 1;
}

/* #sidebar.expand .sidebar-link[data-bs-toggle="collapse"]::after {
    border: solid;
    border-width: 0 .075rem .075rem 0;
    content: "";
    display: inline-block;
    padding: 2px;
    position: absolute;
    right: 1.5rem;
    top: 1.4rem;
    transform: rotate(-135deg);
    transition: all .2s ease-out;
} */

/* #sidebar.expand .sidebar-link[data-bs-toggle="collapse"].collapsed::after {
    transform: rotate(45deg);
    transition: all .2s ease-out;
} */

/* .navbar {
    background-color: #f5f5f5;
    box-shadow: 0 0 2rem 0 rgba(33, 37, 41, .1);
} */

.navbar-expand .navbar-collapse {
    min-width: 200px;
}

.avatar {
    height: 40px;
    width: 40px;
}
.collapse {
  transition: all 0.5 ease-in-out !important;
}


@media (min-width: 768px) {}
.sidebar-link[data-bs-toggle="collapse"]::after {
  border: solid;
  border-width: 0 .075rem .075rem 0;
  content: "";
  display: inline-block;
  padding: 2px;
  position: absolute;
  right: 1.5rem;
  top: 1.4rem;
  transform: rotate(-135deg);
  transition: all .2s ease-out;
}
.sidebar-link[data-bs-toggle="collapse"].collapsed::after {
  transform: rotate(45deg);
  transition: all 0.2s ease-out;
}


.swal2-shown{
  padding-right: 0px !important;
}

.swal2-confirm{
  margin: 1rem !important;
  /* width: 100%; */
}


.popup {
  position: fixed;
  top: -60rem;
  left: 50%;
  /* opacity: 0.1; */
  transform: translateX(-50%);
  width: 300px;
  padding: 20px;
  background-color: white;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  transition: top 0.5s ease-in;
  z-index: 1000;
}
.tran{
  /* background-color: rgba(0, 34, 159, 0.075);
    border-left: 3px solid #4a5cf2; */
    transition: all 1s ease-in-out;
}
.popup.show {
  /* opacity: 1; */
  top: 10%;
  /* transition: top 1s ease-in-out; */
}
.coll{
  background-color: rgba(0, 34, 159, 0.075);
  border-left: 3px solid #4a5cf2;
}
/* .popup-content {
  display: flex;
  flex-direction: column;
  align-items: center;
} */

.close-btn {
  align-self: flex-end;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}
/* button {
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  width: 100%;
  z-index: 10000;
} */
.balance-container{
  overflow-y: visible!important;;
}
.hover_btn:hover{
  background-color: rgb(248, 86, 86);
}
.swal2-styled{
  background-color: green!important ;
}
/* #calendar-wrapper{
  display: block !important;
} */

.not{
  animation: infinite;
}
.list-unstyled{

  transition: height 0.3s ease;
}

.sidebar-dropdown {
  overflow: hidden;
  height: 0;
  transition: height 0.3s ease;
}

.sidebar-dropdown.show {
  height: auto;
  /* transition: height 0.3s ease; */

}
.rotated {
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}

.not-rotated {
  transform: rotate(0deg);
  transition: transform 0.3s ease;
}

.backgroundblue{
  background-color:var(--bs-info);
  /* background-color: #9198dc; */
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
  z-index: 1;
}
.bluebackground{
  background-color:var(--bs-info);
  /* background-color: #9198dc; */
  /* box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5); */
}
.row-1{
  display: none;
}


.dOedVY{
min-width: 190px!important;
}


.rotate-infinite {
  animation: rotate 0.6s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

/* transition for sidebar item */
.sidebar-item .list-unstyled {
  transition: all  0.2s ease-in-out, opacity 0.1s ease-in-out;
  /* transition: min-height  0.5s ease-in-out, opacity 0.5s ease-in-out; */
  max-height: 0;
  min-height: 0; /* تغییر به 0 برای مخفی کردن کامل */
  opacity: 0;
  overflow: hidden;
}

.sidebar-item .list-unstyled.show {
  transition: max-height  0.2s ease-in-out, opacity 0.1s ease-in-out;
  max-height: 300px; /* مقدار حداکثر ارتفاعی که منو باید داشته باشد */
  opacity: 1;
}
