@media (max-width: 768px) {
    .table {
      font-size: 14px; /* Smaller font size for mobile */
    }
    .btn-info {
      font-size: 16px; /* Increase font size for better readability */
      padding: 10px; /* Adjust padding */
    }
    .container {
      width: 90%; /* Full width for smaller screens */
    }
  }
  