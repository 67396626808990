.res{
    max-width: 40%!important;
    height: 54vh !important;;
}
.customer{
    max-width: 40%!important;
    height: 80vh !important;
}
.transition{
    transition: all 10s ease;
    max-width: 40%!important;
    /* width: 40% !important; */
    height: 80vh !important;
    /* height: 80vh !important; */
}
.transition.sellpurchase{
    max-width: 60%!important;
    height: 80vh !important;
    /* transition: all 10s ease; */
}
.item{
    max-width: 500px!important;
    height: 80vh!important;;
}
.setting{
    max-width: 40%!important;
    height: 65vh !important;;
}
@media (max-width: 768px) {
    .transition{
        max-width: 80%!important;
        height: 85vh !important;
    }
    .customer{
        max-width: 80%!important;
        height: 85vh !important;;
    }
    .sellpurchase{
        max-width: 80%!important;
        height: 85vh !important;
    }
    .setting{
        max-width: 80%!important;
        height: 85vh !important;;
    }
    .res{
        /* max-height: 80%; */
        max-width: 90%!important;
        height: 78vh !important;;
    }
    .margin{
        margin-top: 2rem !important;
        /* margin-bottom: 1rem !important; */
    }
}

